import { useEffect, useState } from "react"
import './SettingsPanel.scss'
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { FaFileAlt } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { changePsychoQuestions, changeSurveyQuestions, changeVideoQuestions } from "../../../redux/adminReducer";
import { AppDispatch } from "redux/store";
import { VideoQuestionsForm } from "./SettingsQuestionForms/VideoQuetionsForm";
import { PsychoQuestionsForm } from "./SettingsQuestionForms/PsychoQuestionsForm";
import { SurveyQuestionForm } from "./SettingsQuestionForms/SurveyQuestionsForm";

export const SettingsPanel = () => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const dispatch = useAppDispatch()

    return <div className="wholePage">
        <h1 className="settings-title">Настройки</h1>
        <div className="settings">
            <div className="settings__routes">
                <div className={currentPage === 1 ? 'active' : ''}>
                    <FaFileAlt className="ffile" />
                    <p onClick={() => { setCurrentPage(1) }
                    }>Анкета</p>
                </div>
                <div className={currentPage === 2 ? 'active' : ''}>
                    <CiUser className="ffile" />
                    <p onClick={() => { setCurrentPage(2) }
                    }>Видео-интервью</p>
                </div>
                <div className={currentPage === 3 ? 'active' : ''}>
                    <FaFileAlt className="ffile" />
                    <p onClick={() => { setCurrentPage(3) }
                    }>Тест на определение психотипа</p>
                </div>
            </div>
            <div className="settings__pages">
                <h1>  {currentPage === 1 && 'Анкета'}
                    {currentPage === 2 && 'Видео-интервью'}
                    {currentPage === 3 && 'Тест на определение психотипа'}</h1>
                <div className="settings__pages-inside">
                    {currentPage === 1 && <SurveyQuestionForm dispatch={dispatch} />}
                    {currentPage === 2 && <VideoQuestionsForm dispatch={dispatch} />}
                    {currentPage === 3 && <PsychoQuestionsForm dispatch={dispatch} />}
                </div>

            </div>
        </div>
    </div>

}

/* const Survey: React.FC<{ dispatch: AppDispatch }> = ({ dispatch }) => {

    const questionsBase = useAppSelector(state => state.adminReducer.surveyQuestions)
    const [idToChange, setId] = useState<number[]>([])
    const [questions, setQuestion] = useState(questionsBase)

    useEffect(() => { setId([]) }, [questionsBase])
    const handleQuestionChange = (id: number, newValue: string) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id ? { ...q, question: newValue } : q
            )
        );
    };

    const addQuestion = () => {
        setQuestion([...questions, { id: questions.length, question: '', file: false }])
        setId([...idToChange, questions.length])
    }

    const changeFile = (id: number) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id ? { ...q, file: !q.file } : q
            )
        );
    }

    return (
        <>
            {questions.length > 0 ? (
                questions.map((q) => (
                    <div key={q.id} className="settings__pages-inside-question">
                        <h2>Вопрос {q.id + 1}</h2>
                        <div className="settings__pages-inside-question-line">
                            <p>{!idToChange.includes(q.id) ? q.question : <input value={q.question} onChange={((e) => handleQuestionChange(q.id, e.currentTarget.value))} className="edit-text"></input>}</p>
                            <button onClick={() => {
                                !idToChange.includes(q.id) ? setId([...idToChange, q.id]) : setId(idToChange.filter((id) => { return id !== q.id }))
                            }} className="edit-button">{!idToChange.includes(q.id) ? 'Изменить' : 'Сохранить'}</button>
                            <button onClick={() => {
                                if (idToChange.includes(q.id)) { changeFile(q.id) }
                            }} className={`edit-button ${q.file && 'toDelete'}`}>{q.file ? 'Убрать загрузку файла' : 'Загрузка файла'}</button>
                        </div>

                    </div>

                ))
            ) : (
                <p>Нет доступных вопросов.</p>

            )}
            <button onClick={() => { addQuestion() }} className="question-button">+ Добавить ещё один вопрос</button>
            <div>
                <button onClick={() => { dispatch(changeSurveyQuestions((questions))); setId([]) }} className="send-button">Сохранить изменения</button>
            </div>

        </>
    );
}

 */

