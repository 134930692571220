import { useEffect, useState } from "react"
import { changeSurveyQuestions } from "../../../../redux/adminReducer"
import { useAppSelector } from "../../../../redux/reduxHooks";
import { AppDispatch } from "redux/store"

export const SurveyQuestionForm: React.FC<{ dispatch: AppDispatch }> = ({ dispatch }) => {

    const questionsBase = useAppSelector(state => state.adminReducer.surveyQuestions)
    const [idToChange, setId] = useState<number[]>([])
    const [questions, setQuestion] = useState(questionsBase)

    useEffect(() => { setId([]) }, [questionsBase])
    const handleQuestionChange = (id: number, newValue: string) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id ? { ...q, question: newValue } : q
            )
        );
    };

    const addQuestion = () => {
        setQuestion([...questions, { id: questions.length, question: '', file: false }])
        setId([...idToChange, questions.length])
    }

    const changeFile = (id: number) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id ? { ...q, file: !q.file } : q
            )
        );
    }

    return (
        <>
            {questions.length > 0 ? (
                questions.map((q) => (
                    <div key={q.id} className="settings__pages-inside-question">
                        <h2>Вопрос {q.id + 1}</h2>
                        <div className="settings__pages-inside-question-line">
                            <p>{!idToChange.includes(q.id) ? q.question : <input value={q.question} onChange={((e) => handleQuestionChange(q.id, e.currentTarget.value))} className="edit-text"></input>}</p>
                            <button onClick={() => {
                                !idToChange.includes(q.id) ? setId([...idToChange, q.id]) : setId(idToChange.filter((id) => { return id !== q.id }))
                            }} className="edit-button">{!idToChange.includes(q.id) ? 'Изменить' : 'Сохранить'}</button>
                            <button onClick={() => {
                                if (idToChange.includes(q.id)) { changeFile(q.id) }
                            }} className={`edit-button ${q.file && 'toDelete'}`}>{q.file ? 'Убрать загрузку файла' : 'Загрузка файла'}</button>
                        </div>

                    </div>

                ))
            ) : (
                <p>Нет доступных вопросов.</p>

            )}
            <button onClick={() => { addQuestion() }} className="question-button">+ Добавить ещё один вопрос</button>
            <div>
                <button onClick={() => { dispatch(changeSurveyQuestions((questions))); setId([]) }} className="send-button">Сохранить изменения</button>
            </div>

        </>
    );
}



