import Header from "components/Header/Header"
import {Outlet, useLocation} from "react-router-dom"
import './AdminLayout.scss'
import logonew from './logo1.png'
import { useEffect, useState } from "react";

export const AdminLayout = () => {
    const [linkActive, setLinkActive] = useState<null | number>(null)
    const location = useLocation()
    const links = [
        {
            id: 0,
            href: "/private-office",
            text: "Личный кабинет"
        },
        {
            id: 1,
            href: "/settings-panel",
            text: "Настройки"
        },
        {
            id: 2,
            href: "/directions",
            text: "Направления"
        },
    ]


    useEffect(() => {
        const activeLink = links.find(link => location.pathname.startsWith(link.href));
        if (activeLink) {
            setLinkActive(activeLink.id);
        }
    }, [location.pathname, links]);


    return <div>
        <div className="admin-layout">
            <div className="admin-layout__sidebar">
                <div className="admin-layout-sidebar__logo">
                    <img src={logonew} alt=""/>
                </div>
                <div className="admin-layout__sidebar-routes">
                    {links.map((l) => {
                        return <a href={l.href} className={`route ${linkActive === l.id ? 'open' : ''}`}>{l.text}</a>
                    })}
                </div>
            </div>
            <div className="admin-layout__content">
                <Header onlyLogo={false} isShort={true}></Header>
                <Outlet/>
            </div>
        </div>
    </div>
}