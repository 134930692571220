import { useEffect, useState } from "react"
import { changePsychoQuestions } from "../../../../redux/adminReducer"
import { useAppSelector } from "../../../../redux/reduxHooks";
import { AppDispatch } from "../../../../redux/store"

export const PsychoQuestionsForm: React.FC<{ dispatch: AppDispatch }> = ({ dispatch }) => {

    const questionsBase = useAppSelector(state => state.adminReducer.psychoQuestions)
    const [idToChange, setId] = useState<number[]>([])
    const [psychoQuestions, setQuestion] = useState(questionsBase)
    useEffect(() => { setId([]) }, [questionsBase])

    const handleQuestionChange = (id: number, newValue: string) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id ? { ...q, question: newValue } : q
            )
        );
    };

    const handleAnswerChange = (id: number, newValue: string, ind: number) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id
                    ? {
                        ...q,
                        answers: q.answers.map((answer, index) =>
                            index === ind ? newValue : answer
                        )
                    }
                    : q
            )
        );
    };

    const handleDeleteChange = (id: number, ind: number) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id
                    ? {
                        ...q,
                        answers: q.answers.filter((answer, index) =>
                            index !== ind
                        )
                    }
                    : q
            )
        );
    };

    const addQuestion = () => {
        setQuestion([...psychoQuestions, { id: psychoQuestions.length, question: '', answers: [] }])
        setId([...idToChange, psychoQuestions.length])
    }

    const addAnswer = (id: number, variant: string) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id
                    ? {
                        ...q,
                        answers: [...q.answers, variant]
                    }
                    : q
            )
        );
    }


    return (
        <>
            {psychoQuestions.length > 0 ? (
                psychoQuestions.map((q) => (
                    <div key={q.id} className="settings__pages-inside-question">
                        <h2>Вопрос {q.id + 1}</h2>
                        <div className="video-inside">
                            <div className="settings__pages-inside-question-video">
                                <p>{!idToChange.includes(q.id) ? q.question : <input value={q.question} onChange={((e) => handleQuestionChange(q.id, e.currentTarget.value))} className="edit-text"></input>}</p>
                                <div className="answers-block">
                                    <p className="psycho-answers">{q.answers.map((a, ind) => {
                                        return !idToChange.includes(q.id) ? <div>{a}</div> : (<div>
                                            <input onChange={((e) => handleAnswerChange(q.id, e.currentTarget.value, ind))} className="edit-text" value={a}></input>
                                            <button onClick={() => {
                                                handleDeleteChange(q.id, ind)
                                            }} className="delete-button">X</button>

                                        </div>)

                                    })}</p>
                                    {idToChange.includes(q.id) && <button onClick={() => addAnswer(q.id, '')} className="question-button">+ Добавить вариант</button>}
                                </div>
                                <div>

                                </div>


                            </div>
                            <div className="video-edit">
                                <button onClick={() => {
                                    !idToChange.includes(q.id) ? setId([...idToChange, q.id]) : setId(idToChange.filter((id) => { return id !== q.id }))
                                }} className="edit-button">{!idToChange.includes(q.id) ? 'Изменить' : 'Сохранить'}</button>
                            </div>
                        </div>
                    </div>

                ))
            ) : (
                <p>Нет доступных вопросов.</p>
            )}
            <button onClick={() => addQuestion()} className="question-button">+ Добавить ещё один вопрос</button>
            <div>
                <button className="send-button" onClick={() => { dispatch(changePsychoQuestions((psychoQuestions))); setId([]) }}>Сохранить изменения</button>
            </div>
        </>
    );
}