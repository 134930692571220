import { useState } from "react";
import { changeVideoQuestions } from "../../../../redux/adminReducer";
import { useAppSelector } from "../../../../redux/reduxHooks";
import { AppDispatch } from "redux/store";

export const VideoQuestionsForm: React.FC<{ dispatch: AppDispatch }> = ({ dispatch }) => {

    const handleNameChange = (id: number, newName: string) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id ? { ...q, question: newName } : q
            )
        );
    };

    const handleDescChange = (id: number, newDesc: string) => {
        setQuestion(prevQuestions =>
            prevQuestions.map(q =>
                q.id === id ? { ...q, questionDesc: newDesc } : q
            )
        );
    };

    const addQuestion = () => {
        setQuestion([...videoQuestions, { id: videoQuestions.length, question: '', questionDesc: '' }])
        setId([...idToChange, videoQuestions.length])
    }

    const questionsBase = useAppSelector(state => state.adminReducer.videoQuestions)
    const [idToChange, setId] = useState<number[]>([])
    const [videoQuestions, setQuestion] = useState(questionsBase)

    return (
        <>
            {videoQuestions.length > 0 ? (
                videoQuestions.map((q) => (
                    <div key={q.id} className="settings__pages-inside-question">
                        <h2>Вопрос {q.id + 1}</h2>
                        <div className="video-inside">
                            <div className="settings__pages-inside-question-video">
                                <p>{!idToChange.includes(q.id) ? q.question : <input onChange={(e) => handleNameChange(q.id, e.currentTarget.value)} value={q.question} className="edit-text"></input>}</p>
                                <p className="video-desc">{!idToChange.includes(q.id) ? q.questionDesc : <textarea onChange={(e) => handleDescChange(q.id, e.currentTarget.value)} value={q.questionDesc} className="big-text"></textarea>}</p>

                            </div>
                            <div className="video-edit">
                                <button onClick={() => {
                                    !idToChange.includes(q.id) ? setId([...idToChange, q.id]) : setId(idToChange.filter((id) => { return id !== q.id }))
                                }} className="edit-button">{!idToChange.includes(q.id) ? 'Изменить' : 'Сохранить'}</button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>Нет доступных вопросов.</p>
            )}
            <button onClick={() => addQuestion()} className="question-button">+ Добавить ещё один вопрос</button>
            <div>
                <button onClick={() => { dispatch(changeVideoQuestions((videoQuestions))); setId([]) }} className="send-button">Сохранить изменения</button>
            </div>
        </>
    );
}