// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-layout {
  gap: 50px;
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  padding: 20px;
}
.admin-layout__sidebar {
  flex-shrink: 0;
  width: 293px;
  position: sticky;
  background: rgb(115, 25, 117);
  border-radius: 15px;
  min-height: calc(100vh - 40px);
  top: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.admin-layout__sidebar-routes {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.admin-layout__sidebar-routes a {
  color: white;
  padding: 20px;
  font-size: 21px;
  cursor: pointer;
}
.admin-layout__sidebar-routes .open {
  border-radius: 20px 0 0 20px;
  width: 140%;
  background-color: black;
}
.admin-layout__content {
  flex-grow: 1;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/AdminLayout/AdminLayout.scss"],"names":[],"mappings":"AAAA;EAGI,SAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,aAAA;AADJ;AAEI;EACI,cAAA;EACA,YAAA;EACA,gBAAA;EACA,6BAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AAAR;AACQ;EAEI,gBAAA;EACA,aAAA;EACA,sBAAA;AAAZ;AACY;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,eAAA;AACZ;AACa;EAGG,4BAAA;EACA,WAAA;EACA,uBAAA;AADhB;AAMI;EACI,YAAA;EACA,YAAA;AAJR","sourcesContent":[".admin-layout \n{\n   \n    gap: 50px;\n    display: flex;\n    align-items: flex-start;\n    min-height: 100vh;\n    padding: 20px;\n    &__sidebar {\n        flex-shrink: 0;\n        width: 293px;\n        position: sticky;\n        background: rgba(115, 25, 117, 1);\n        border-radius: 15px;\n        min-height: calc(100vh - 40px);\n        top: 20px;\n        color: white;   \n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        padding: 20px;\n        &-routes \n        {\n            margin-top: 30px;\n            display: flex;\n            flex-direction: column;\n            a {\n            color: white;   \n            padding: 20px;\n            font-size: 21px;\n            cursor: pointer;\n            }\n             .open \n            {\n                \n                border-radius: 20px 0 0 20px;\n                width: 140%;\n                background-color: black;\n            } \n        }\n    \n    }\n    &__content{\n        flex-grow: 1;\n        height:100%;\n      }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
